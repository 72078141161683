/* xrpl-client_js new xrpl-client.XrplClient().close() */

// const { XrplClient } = require("xrpl-client");

const closeXrplClient = async ({ consoleLog = false, xrplClient }) => {
  try {
    const response = await xrplClient.close();

    if (response?.error) {
      if (consoleLog) console.error("ERROR in closeClient: ", response.error);
      return {
        error: "closeClient",
        errorData: response,
      };
    }

    return true;
  } catch (error) {
    if (consoleLog) console.error("ERROR in closeXrplClient: ", error);
    return {
      error: "closeXrplClient",
      errorData: {
        name: error?.name,
        message: error?.message,
        stack: error?.stack,
      },
    };
  }
};
exports.closeXrplClient = closeXrplClient;
