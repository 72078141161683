import React, { useState, useEffect } from "react";

const NftBonus = () => {
  //

  return (
    <>
      <div className="bonus">
        <div className="item">
          <div className="description">
            <label>110% Bonus</label>
            <span>retseJ #589</span>
          </div>
          <div className="images">
            <div className="image">
              <img src="images/nfts/589.png" alt="" />
            </div>
          </div>
        </div>

        <div className="item">
          <div className="description">
            <label>100% Bonus</label>
            <span>Diamond & Holo same character</span>
          </div>
          <div className="images">
            <div className="image">
              <img src="images/nfts/1.png" alt="" />
            </div>
            <div className="image">
              <img src="images/nfts/23.webp" alt="" />
            </div>
          </div>
        </div>

        <div className="item">
          <div className="description">
            <label>50% Bonus</label>
            <span>Diamond background</span>
          </div>
          <div className="images">
            <div className="image">
              <img src="images/nfts/20.png" alt="" />
            </div>
          </div>
        </div>

        <div className="item">
          <div className="description">
            <label>40% Bonus</label>
            <span>Holo background</span>
          </div>
          <div className="images">
            <div className="image">
              <img src="images/nfts/74.webp" alt="" />
            </div>
          </div>
        </div>

        <div className="item">
          <div className="description">
            <label>10-30% Bonus</label>
            <span>2-4 same card & character</span>
          </div>
          <div className="images">
            <div className="group">
              <div className="image">
                <img src="images/nfts/114.png" alt="" />
              </div>
              <div className="image">
                <img src="images/nfts/122.png" alt="" />
              </div>
            </div>
            <div className="group">
              <div className="image">
                <img src="images/nfts/126.png" alt="" />
              </div>
              <div className="image">
                <img src="images/nfts/118.png" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="item">
          <div className="description">
            <label>10-30% Bonus</label>
            <span>2-4 same color & character</span>
          </div>
          <div className="images">
            <div className="group">
              <div className="image">
                <img src="images/nfts/178.png" alt="" />
              </div>
              <div className="image">
                <img src="images/nfts/179.png" alt="" />
              </div>
            </div>
            <div className="group">
              <div className="image">
                <img src="images/nfts/180.png" alt="" />
              </div>
              <div className="image">
                <img src="images/nfts/181.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NftBonus;
