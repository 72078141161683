const { sendXrplCommand } = require("./sendXrplCommand");

const getNftsByIssuerCommand = ({
  id = undefined,
  issuer, // String	A unique identifier for the account, most commonly the account's address.
  nft_taxon = undefined, // Integer	(Optional) Use to filter NFTs issued by this issuer that have this taxon.
  limit = undefined, // Number	No	The maximum number of objects to include in the results. Must be within the inclusive range 10 to 400 on non-admin connections. The default is 200.
  marker = undefined, // Marker	No	Value from a previous paginated response. Resume retrieving data where that response left off.
  ledger_hash = undefined, // Hash	No	A 20-byte hex string for the ledger version to use. (See Specifying Ledgers)
  ledger_index = "validated", // Ledger Index	No	The ledger index of the ledger to use, or a shortcut string to choose a ledger automatically. (See Specifying Ledgers)
}) => {
  return {
    command: "nfts_by_issuer",
    id,
    issuer,
    nft_taxon,
    limit,
    marker,
    ledger_hash,
    ledger_index,
  };
};

const getNftsByIssuer = async ({
  consoleLog,
  xrplClient,
  id,
  issuer,
  nft_taxon,
  limit,
  marker,
  ledger_hash,
  ledger_index,
}) => {
  const command = getNftsByIssuerCommand({
    id,
    issuer,
    nft_taxon,
    limit,
    marker,
    ledger_hash,
    ledger_index,
  });
  const response = await sendXrplCommand({ consoleLog, xrplClient, command });
  return response;
};

const getAllNftsByIssuer = async ({
  consoleLog,
  xrplClient,
  issuer,
  nft_taxon,
  limit,
  ledger_hash,
  ledger_index,
  maxErrors = 0,
}) => {
  let nfts = [];
  let marker;
  let errorCount = 0;
  while (true) {
    const response = await getNftsByIssuer({
      consoleLog,
      xrplClient,
      issuer,
      nft_taxon,
      limit,
      marker: marker ? marker : undefined,
      ledger_hash,
      ledger_index,
    });

    if (response?.error) {
      errorCount++;
      if (errorCount > maxErrors) return response;
    } else {
      errorCount = 0;

      nfts = response?.nfts?.length > 0 ? [...nfts, ...response.nfts] : nfts;

      marker = response?.marker ? response.marker : undefined;
      if (!marker) return nfts;
    }
  }
};
exports.getAllNftsByIssuer = getAllNftsByIssuer;
