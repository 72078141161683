/* xrpl-client_js new xrpl-client.XrplClient() */

const { XrplClient } = require("xrpl-client");

const XRPL_MAINNET = "wss://s1.ripple.com";

const getXrplClient = async ({ consoleLog = false }) => {
  try {
    const xrplClient = new XrplClient(XRPL_MAINNET);
    if (xrplClient?.error) {
      if (consoleLog)
        console.error("ERROR in getXrplClient: ", xrplClient.error);
      return {
        error: "getXrplClient",
        errorData: xrplClient,
      };
    }

    await xrplClient.ready();

    if (xrplClient?.error) {
      if (consoleLog)
        console.error("ERROR in getXrplClient: ", xrplClient.error);
      return {
        error: "getXrplClient",
        errorData: xrplClient,
      };
    }

    return xrplClient;
  } catch (error) {
    if (consoleLog) console.error("ERROR in getXrplClient: ", error);
    return {
      error: "getXrplClient",
      errorData: {
        name: error?.name,
        message: error?.message,
        stack: error?.stack,
      },
    };
  }
};
exports.getXrplClient = getXrplClient;

// Arguments:
// endpoints (string | array) The WebSocket endpoint to connect to (e.g. your own node) as a string, or an array (string[]) with multiple endpoints used in specified order. Empty string or array if you want to use the default nodes, but specify custom options using the second param.: example: ['wss://xrplcluster.com', 'wss://xrpl.link', 'wss://s2.ripple.com']
// options (object):
// assumeOfflineAfterSeconds, Number » default 15, this setting will check if the XRPL node on the other end of the connection is alive and sending regular server_info responses (this lib. queries for them). After the timeout, the lib. will disconnect from the node and try to reconnect.
// maxConnectionAttempts, Number | null » default null in case of one endpoint, or 3 if an array with endpoints is provided, if (when initially connecting or reconnecting) no (new) connection could be setup in this attempts (see: connectAttemptTimeoutSeconds per call) consider the connection dead. Cancel all connect/reconnect attempts, clear the command buffer. An error will be thrown.
// connectAttemptTimeoutSeconds, Number » default 3, this setting is the max. delay between reconnect attempts, if no connection could be setup to the XRPL node. A backoff starting at one second, growing with 20% per attempt until this value is reached will be used.
// feeDropsDefault, Number » default 12, The min. amount of node reported transaction fee (in drops) respected for the getState() reported last/avg fee amount.
// feeDropsMax, Number » default 3600, The max. amount of node reported transaction fee (in drops) respected for the getState() reported last/avg fee amount.
// tryAllNodes, Boolean » default false, If connection attempts will be made to all nodes at the same time, connecting the client to the first to respond.

// Methods:
// send({ command: "..."}, {SendOptions}) » Promise<AnyJson | CallResponse> » Send a command to the connected XRPL node.
// ready() » Promise<self> » fires when you're fully connected. While the state event (and getState() method) only return the WebSocket online state, ready() will only return (async) if the first ledger data has been received and the last ledger index is known.
// getState() » ConnectionState » Get the connection, connectivity & server state (e.g. fees, reserves).
// close() » void » Close the connection, but allow the object to be used again (using reinstate()).
// reinstate(options?: {forceNextUplink: boolean}) » void » Reconnect the object when in closed state (after calling close()). By passing forceNextUplink: true (default false) the connection will be reinstated to the next uplink instead of starting again from the first provided uplink (constructor). If the Client class was constructed with the tryAllNodes: true option, there is no "next uplink", as all will be tried. In which case the forceNextUplink: true option will be ignored.
// destroy() » void » Fully close the entire object (can't be used again).
