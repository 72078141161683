const { BigNumber } = require("bignumber.js");

const { sendXrplCommand } = require("./sendXrplCommand");

const getAmmInfoCommand = ({
  id = undefined,
  account = undefined, // String - Address	No	Show only LP Tokens held by this liquidity provider.
  amm_account = undefined, // String - Address	No	The address of the AMM's special AccountRoot. (This is the issuer of the AMM's LP Tokens.)
  asset1_issuer = undefined, // Object	No	One of the assets of the AMM to look up, as an object with currency and issuer fields (omit issuer for XRP), like currency amounts.
  asset1_currency = undefined,
  asset2_issuer = undefined, // Object	No	The other of the assets of the AMM, as an object with currency and issuer fields (omit issuer for XRP), like currency amounts.
  asset2_currency = undefined,
  ledger_hash = undefined, // String	No	A 20-byte hex string for the ledger version to use. (See Specifying Ledgers)
  ledger_index = "validated", // Number or String	No	The ledger index of the ledger to use, or a shortcut string to choose a ledger automatically. (See Specifying Ledgers)
}) => {
  return {
    command: "amm_info",
    id,
    account,
    amm_account,
    asset: amm_account
      ? undefined
      : {
          currency: asset1_currency,
          issuer: asset1_issuer,
        },
    asset2: amm_account
      ? undefined
      : {
          currency: asset2_currency,
          issuer: asset2_issuer,
        },
    ledger_hash,
    ledger_index,
  };
};

const parseAmmInfo = (ammInfo) => {
  const ledgerIndex = ammInfo?.ledger_index
    ? ammInfo.ledger_index
    : ammInfo?.ledger_current_index;

  const ammAsset1 =
    ammInfo?.amm?.amount?.value !== undefined
      ? {
          issuer: ammInfo.amm.amount.issuer,
          currency: ammInfo.amm.amount.currency,
          ammSupply: Number(ammInfo.amm.amount.value),
        }
      : ammInfo?.amm?.amount2?.value !== undefined
      ? {
          issuer: ammInfo.amm.amount2.issuer,
          currency: ammInfo.amm.amount2.currency,
          ammSupply: Number(ammInfo.amm.amount2.value),
        }
      : undefined;

  const ammAsset2 =
    ammInfo?.amm?.amount?.value !== undefined
      ? ammInfo?.amm?.amount2?.value !== undefined
        ? {
            issuer: ammInfo.amm.amount2.issuer,
            currency: ammInfo.amm.amount2.currency,
            ammSupply: Number(ammInfo.amm.amount2.value),
          }
        : {
            issuer: undefined,
            currency: "XRP",
            ammSupply: Number(new BigNumber(ammInfo.amm.amount2).div(1000000)),
          }
      : ammInfo?.amm?.amount !== undefined
      ? {
          issuer: undefined,
          currency: "XRP",
          ammSupply: Number(new BigNumber(ammInfo.amm.amount).div(1000000)),
        }
      : undefined;

  const ammSupply =
    ammInfo?.amm?.lp_token?.value !== undefined
      ? Number(ammInfo.amm.lp_token.value)
      : undefined;
  const lpToken = {
    issuer: ammInfo?.amm?.lp_token?.issuer,
    currency: ammInfo?.amm?.lp_token?.currency,
  };

  const ammFee =
    ammInfo?.amm?.trading_fee !== undefined
      ? Number(new BigNumber(ammInfo.amm.trading_fee).div(1000))
      : undefined;

  const ammTotal =
    ammAsset1?.ammSupply > 0 && ammAsset2?.ammSupply > 0
      ? Number(
          new BigNumber(ammAsset1.ammSupply).multipliedBy(ammAsset2.ammSupply)
        )
      : undefined;

  const ammPrice = {
    inAsset1:
      ammAsset1?.ammSupply > 0 && ammAsset2?.ammSupply > 0
        ? Number(new BigNumber(ammAsset1.ammSupply).div(ammAsset2.ammSupply))
        : undefined,

    inAsset2:
      ammAsset1?.ammSupply > 0 && ammAsset2?.ammSupply > 0
        ? Number(new BigNumber(ammAsset2.ammSupply).div(ammAsset1.ammSupply))
        : undefined,
  };

  const lpTokenPrice = {
    inAsset1: Number(
      new BigNumber(ammAsset1.ammSupply)
        .multipliedBy(2)
        .div(new BigNumber(ammSupply))
    ),
    inAsset2: Number(
      new BigNumber(ammAsset2.ammSupply)
        .multipliedBy(2)
        .div(new BigNumber(ammSupply))
    ),
  };

  const error = ammInfo?.error && ammInfo?.error !== "actNotFound";

  return {
    error,
    ammSupply,
    ammFee,
    ammAsset1,
    ammAsset2,
    ammTotal,
    ammPrice,
    lpToken,
    lpTokenPrice,
    ledgerIndex,
  };
};

const getAmmInfo = async ({
  consoleLog,
  xrplClient,
  id,
  account,
  amm_account,
  asset1_issuer,
  asset1_currency,
  asset2_issuer,
  asset2_currency,
  ledger_hash,
  ledger_index,
}) => {
  const command = getAmmInfoCommand({
    id,
    account,
    amm_account,
    asset1_issuer,
    asset1_currency,
    asset2_issuer,
    asset2_currency,
    ledger_hash,
    ledger_index,
  });
  const response = await sendXrplCommand({ consoleLog, xrplClient, command });
  return response;
};

const getAmmInfoParsed = async ({
  consoleLog,
  xrplClient,
  ammAccount,
  asset1,
  asset2,
  ledgerIndex: ledger_index,
}) => {
  const ammInfo = await getAmmInfo({
    consoleLog,
    xrplClient,
    amm_account: ammAccount,
    asset1_issuer: asset1?.issuer,
    asset1_currency: asset1?.currency,
    asset2_issuer: asset2?.issuer,
    asset2_currency: asset2?.currency,
    ledger_index,
  });

  const {
    error,
    ammSupply,
    ammFee,
    ammAsset1,
    ammAsset2,
    ammTotal,
    ammPrice,
    lpToken,
    lpTokenPrice,
    ledgerIndex,
  } = parseAmmInfo(ammInfo);

  return {
    error,
    ammSupply,
    ammFee,
    ammAsset1,
    ammAsset2,
    ammTotal,
    ammPrice,
    lpToken,
    lpTokenPrice,
    ledgerIndex,
  };
};
exports.getAmmInfoParsed = getAmmInfoParsed;
