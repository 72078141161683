import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import { getXrplClient } from "utils/getXrplClient";
import { closeXrplClient } from "utils/closeXrplClient";
import { getAllNftsByIssuer } from "utils/getNftHolderCount";
import { getAmmInfoParsed } from "utils/getAmmInfoParsed";
import {
  BEAR_NFT_ISSUER,
  BEAR_LP_ISSUER,
  DAILY_REWARDS_LP,
  DAILY_REWARDS_NFT,
} from "config/index";
import NftBonus from "./NftBonus";
import { getAccountLinesParsed } from "utils/getAccountLinesParsed";
import { BEAR_LP_CURRENCY } from "config/index";
import { BEAR_ISSUER } from "config/index";
import { BEAR_CURRENCY } from "config/index";

const consoleLog = true;

const DailyRewards = () => {
  //
  const [loading, setLoading] = useState(true);
  const [nftHolderCount, setNftHolderCount] = useState();
  const [ammTvl, setAmmTvl] = useState();
  const [apy, setApy] = useState();
  const [rewardsNft, setRewardsNft] = useState();
  const [rewardsLp, setRewardsLp] = useState();

  useEffect(() => {
    //
    const getChainData = async () => {
      const xrplClient = await getXrplClient({ consoleLog });
      if (xrplClient?.error) return xrplClient;

      // Get NFT holders
      const nftsByIssuer = await getAllNftsByIssuer({
        consoleLog,
        xrplClient,
        issuer: BEAR_NFT_ISSUER,
      });
      if (nftsByIssuer?.error) return nftsByIssuer;

      const nftHolders = nftsByIssuer.reduce((acc, item) => {
        if (item.owner !== BEAR_NFT_ISSUER) {
          let ownerFound = acc.find((accItem) => accItem === item.owner);
          if (!ownerFound) acc.push(item.owner);
        }
        return acc;
      }, []);

      setNftHolderCount(nftHolders.length);

      // Get AMM info
      const amm = await getAmmInfoParsed({
        consoleLog,
        xrplClient,
        asset1: { issuer: BEAR_ISSUER, currency: BEAR_CURRENCY },
        asset2: { currency: "XRP" },
        // ammAccount: BEAR_LP_ISSUER,
      });
      if (amm?.error) return amm;
      if (!amm?.ammAsset2?.ammSupply) return;
      if (!amm?.lpTokenPrice?.inAsset1) return;

      const lpTokenPrice = amm.lpTokenPrice.inAsset1;

      let totalAmmBear = amm.ammAsset1.ammSupply * 2;
      let totalAmmXrp = amm.ammAsset2.ammSupply * 2;
      totalAmmXrp = totalAmmXrp.toLocaleString("en-US").split(".")[0];

      setAmmTvl(totalAmmXrp);

      const accountLines = await getAccountLinesParsed({
        consoleLog,
        xrplClient,
        account: BEAR_NFT_ISSUER,
      });
      if (accountLines?.error) return accountLines;
      if (!accountLines?.holdingBalances) return;

      let bearLpHolding = accountLines.holdingBalances.find(
        (holding) =>
          holding.issuer === BEAR_LP_ISSUER &&
          holding.currency === BEAR_LP_CURRENCY
      );

      bearLpHolding = bearLpHolding.balance * lpTokenPrice;
      totalAmmBear = totalAmmBear - bearLpHolding;

      // Set rewards & APY
      let dailyRewardsNft =
        DAILY_REWARDS_NFT.toLocaleString("en-US").split(".")[0];
      setRewardsNft(dailyRewardsNft);

      let dailyRewardsLp = DAILY_REWARDS_LP;
      setRewardsLp(dailyRewardsLp.toLocaleString("en-US").split(".")[0]);

      const yearlyRewards = dailyRewardsLp * 365;
      let tvlApy = (100 / totalAmmBear) * yearlyRewards;
      tvlApy =
        tvlApy % 1 === 0
          ? tvlApy.toLocaleString("en-US")
          : tvlApy.toLocaleString("en-US", {
              minimumFractionDigits: 1,
              maximumFractionDigits: 2,
            });

      setApy(tvlApy);

      setLoading(false);

      await closeXrplClient({ consoleLog, xrplClient });
    };

    if (loading) getChainData();
  }, []);

  return (
    <>
      <div className="content-box daily-rewards">
        <div className="header">
          <div className="title">Daily Airdrops</div>

          <div className="text">
            <p className="p1">
              Earn <span>daily rewards</span> by providing liquidity to the{" "}
              <span>BEAR/XRP LP</span> or by holding&nbsp;
              <span>BEAR&nbsp;NFTs</span>
            </p>
            <p>
              The more BEAR LP tokens or NFTs you hold, the more{" "}
              <span>airdrops</span> you'll&nbsp;get
            </p>
          </div>
        </div>

        <div className="content">
          <div className="box">
            <div className="title">
              Deposit BEAR & XRP to the liquidity&nbsp;pool:
            </div>
            <motion.button
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
              className="button"
              layout="position"
            >
              <Link
                className="link"
                to="https://orchestra.finance/pools/XRP-4245415200000000000000000000000000000000.rBEARGUAsyu7tUw53rufQzFdWmJHpJEqFW"
                target="_blank"
                rel="noopener noreferrer"
              >
                Deposit to LP
              </Link>
            </motion.button>
            <div className="data">
              <div className="item">
                <label>Current APR:</label>
                <span>{loading ? "Loading" : `${apy}%`}</span>
              </div>
              <div className="item">
                <label>BEAR/XRP LP TVL:</label>
                <span>{loading ? "Loading" : `${ammTvl} XRP`}</span>
              </div>
              <div className="item">
                <label>Daily LP rewards:</label>
                <span>{loading ? "Loading" : `${rewardsLp} $BEAR`}</span>
              </div>
            </div>
          </div>

          <div className="box">
            <div className="title">
              Hold NFTs of BEAR Ultra Rare&nbsp;Collection:
            </div>
            <motion.button
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
              className="button"
              layout="position"
            >
              <Link
                className="link"
                to="https://xrp.cafe/collection/bearxrpl"
                target="_blank"
                rel="noopener noreferrer"
              >
                Buy BEAR NFT
              </Link>
            </motion.button>
            <div className="data">
              <div className="item">
                <label>Total NFT holders:</label>
                <span>{loading ? "Loading" : `${nftHolderCount} wallets`}</span>
              </div>
              <div className="item">
                <label>Daily NFT Rewards:</label>
                <span>{loading ? "Loading" : `${rewardsNft} $BEAR`}</span>
              </div>
            </div>
            <div className="description">
              <p className="p1">BEAR NFTs are like a cardgame.</p>
              <p>
                Some cards & combinations will give&nbsp;you&nbsp;a&nbsp;
                <span>bonus</span>:
              </p>
            </div>
            <NftBonus />
          </div>
        </div>
      </div>
    </>
  );
};

export default DailyRewards;
