const sendXrplCommand = async ({
  consoleLog = false,
  xrplClient,
  command,
  timeoutSeconds = undefined,
  timeoutStartsWhenOnline = undefined,
  sendIfNotReady = undefined,
  noReplayAfterReconnect = undefined,
}) => {
  try {
    const response = await xrplClient.send(command, {
      timeoutSeconds,
      timeoutStartsWhenOnline,
      sendIfNotReady,
      noReplayAfterReconnect,
    });
    if (response?.error) {
      if (consoleLog)
        console.error("ERROR in sendXrplCommand: ", response.error);
      return {
        error: "sendXrplCommand",
        errorData: response,
      };
    }
    return response;
  } catch (error) {
    if (consoleLog) console.error("ERROR in sendXrplCommand: ", error);
    return {
      error: "sendXrplCommand",
      errorData: {
        name: error?.name,
        message: error?.message,
        stack: error?.stack,
      },
    };
  }
};
exports.sendXrplCommand = sendXrplCommand;
