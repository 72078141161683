import React, { useState, useEffect } from "react";

import Header from "./Header";
import Network2 from "./Network2";
import Tokenomics from "./Tokenomics";
import Socials from "./Socials";
import Liquidity from "./Liquidity";
import DailyRewards from "./DailyRewards";
import Intro from "./Intro";
import Discord from "./Discord";

const MainPage = () => {
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    const checkFontLoad = async () => {
      await document.fonts.ready;
      setFontsLoaded(true);
    };
    checkFontLoad();
  }, []);

  return (
    <>
      {(!fontsLoaded || !imagesLoaded) && <div className="main-loading"></div>}
      <div className="main-page">
        <Header
          setImagesLoaded={setImagesLoaded}
          isLoaded={fontsLoaded && imagesLoaded}
        />
        <Intro />
        <Network2 />
        <DailyRewards />
        <Discord />
        <Tokenomics />
        <Socials />
      </div>
    </>
  );
};

export default MainPage;
