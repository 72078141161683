import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Network2 = () => {
  return (
    <>
      <div className="content-box network other">
        <div className="header">
          <div className="title">Network</div>

          <div className="text">
            BEAR&nbsp;is&nbsp;available for trading
            on&nbsp;the&nbsp;XRPL&nbsp;DEX
          </div>
        </div>

        <div className="content">
          <motion.div
            className="sologenic"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.2 }}
            layout="position"
          >
            <Link
              className="link"
              to="https://sologenic.org/trade?network=mainnet&market=4245415200000000000000000000000000000000+rBEARGUAsyu7tUw53rufQzFdWmJHpJEqFW/XRP"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="image">
                <img src="images/sologenic.png" alt="" />
              </div>
            </Link>
          </motion.div>
          <motion.div
            className="xmagnetic"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.2 }}
            layout="position"
          >
            <Link
              className="link"
              to="https://xmagnetic.org/dex/BEAR+rBEARGUAsyu7tUw53rufQzFdWmJHpJEqFW_XRP+XRP?network=mainnet"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="image">
                <img src="images/xmagnetic.png" alt="" />
              </div>
            </Link>
          </motion.div>

          <motion.div
            className="firstledger"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.2 }}
            layout="position"
          >
            <Link
              className="link"
              to="https://firstledger.net/token/rBEARGUAsyu7tUw53rufQzFdWmJHpJEqFW/4245415200000000000000000000000000000000"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="image">
                <img src="images/firstledger.png" alt="" />
              </div>
            </Link>
          </motion.div>
          <motion.div
            className="coingecko"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.2 }}
            layout="position"
          >
            <Link
              className="link"
              to="https://www.coingecko.com/en/coins/bear-3"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="image">
                <img src="images/coingecko.png" alt="" />
              </div>
            </Link>
          </motion.div>

          <motion.div
            className="gatehub"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.2 }}
            layout="position"
          >
            <Link
              className="link"
              to="https://gatehub.net/markets/4245415200000000000000000000000000000000+rBEARGUAsyu7tUw53rufQzFdWmJHpJEqFW/XRP"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="image">
                <img src="images/gatehub.png" alt="" />
              </div>
            </Link>
          </motion.div>
          <motion.div
            className="xaman"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.2 }}
            layout="position"
          >
            <Link
              className="link"
              to="https://xumm.app/detect/xapp:xumm.dex?issuer=rBEARGUAsyu7tUw53rufQzFdWmJHpJEqFW&currency=4245415200000000000000000000000000000000"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="image">
                <img src="images/xaman.svg" alt="" />
              </div>
            </Link>
          </motion.div>
          <motion.div
            className="xrplto"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.2 }}
            layout="position"
          >
            <Link
              className="link"
              to="https://xrpl.to/token/756591d27576054e07c93fe90e7c1d25/trade"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="image">
                <img src="images/xrplto.svg" alt="" />
              </div>
            </Link>
          </motion.div>
          <motion.div
            className="orchestra"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.2 }}
            layout="position"
          >
            <Link
              className="link"
              to="https://orchestra.finance/pools/4245415200000000000000000000000000000000.rBEARGUAsyu7tUw53rufQzFdWmJHpJEqFW-XRP"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="image">
                <img src="images/orchestra.svg" alt="" />
              </div>
            </Link>
          </motion.div>
          <motion.div
            className="anodos"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.2 }}
            layout="position"
          >
            <Link
              className="link"
              to="https://swap.anodos.finance/liquidity-swap/XRP-4245415200000000000000000000000000000000.rBEARGUAsyu7tUw53rufQzFdWmJHpJEqFW"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="image">
                <img src="images/anodos.png" alt="" />
              </div>
            </Link>
          </motion.div>
          <motion.div
            className="xpmarket"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.2 }}
            layout="position"
          >
            <Link
              className="link"
              to="https://xpmarket.com/dex/BEAR-rBEARGUAsyu7tUw53rufQzFdWmJHpJEqFW/XRP"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="image">
                <svg fill="#6d1fee" viewBox="0 0 140 26">
                  <path
                    d="M12.5238 2.8161H2.54761L4.97617 6.73897H10.0714L12.619 10.8613H17.5L12.5238 2.8161Z"
                    fill="inherit"
                  ></path>
                  <path
                    d="M0 14.7842L4.97618 22.8294L7.42856 18.8843L4.88094 14.7842L7.42856 10.684L4.97618 6.73895L0 14.7842Z"
                    fill="inherit"
                  ></path>
                  <path
                    d="M17.4046 18.9065L22.3808 10.8613H17.4999L14.9523 14.9614H9.85703L7.42847 18.9065H17.4046Z"
                    fill="inherit"
                  ></path>
                  <path
                    d="M37.238 19.7486L31.8808 12.7451L37.1904 5.89667H33.3332L29.8332 10.6174L26.3809 5.89667H22.4285L27.7618 12.7451L22.3809 19.7486H26.238L29.7618 14.8727L33.357 19.7486H37.238Z"
                    fill="inherit"
                  ></path>
                  <path
                    d="M53.5476 9.93042C53.1905 9.04389 52.6429 8.26818 51.9524 7.58113C51.2381 6.89407 50.4286 6.36216 49.5 6.02971C48.5952 5.6751 47.5714 5.51996 46.5 5.51996C45.1667 5.51996 43.9524 5.78592 42.9048 6.31783C42.381 6.58379 41.9286 6.87191 41.5238 7.22652V5.91889H38.4048V25.5111H41.6429V18.6405C42 18.9286 42.381 19.1946 42.8572 19.3941C43.9048 19.9038 45.0952 20.1476 46.4048 20.1476C47.5 20.1476 48.5476 19.9703 49.4762 19.6379C50.4048 19.2833 51.2381 18.7735 51.9524 18.0864C52.6667 17.3994 53.2143 16.6015 53.5714 15.7372C53.9286 14.8506 54.119 13.8976 54.0952 12.8338C54.0714 11.77 53.9048 10.8169 53.5476 9.93042ZM50.7857 12.8116C50.7857 13.4765 50.6667 14.0749 50.4524 14.629C50.2381 15.1609 49.9048 15.6485 49.4762 16.0696C49.0238 16.4907 48.5476 16.801 48.0238 17.0226C47.4762 17.2221 46.881 17.3329 46.2143 17.3329C45.5238 17.3329 44.9048 17.2221 44.3572 17.0226C43.8095 16.8231 43.3333 16.4907 42.881 16.0696C42.4524 15.6485 42.1191 15.1609 41.9048 14.629C41.6905 14.0971 41.5714 13.4987 41.5714 12.8116C41.5714 12.1467 41.6905 11.5483 41.9048 10.9942C42.1191 10.4623 42.4524 9.97475 42.881 9.55365C43.3095 9.13255 43.8095 8.82226 44.3572 8.60063C44.9048 8.40116 45.5238 8.29035 46.2143 8.29035C46.881 8.29035 47.4762 8.40116 48.0238 8.60063C48.5714 8.8001 49.0476 9.13255 49.4762 9.55365C49.9048 9.97475 50.2381 10.4623 50.4524 10.9942C50.6667 11.5483 50.7857 12.1467 50.7857 12.8116Z"
                    fill="inherit"
                  ></path>
                  <path
                    d="M74.6903 10.7283V19.2167H72.5474V10.7283C72.5474 9.86389 72.2617 9.15467 71.6665 8.60059C71.095 8.04651 70.3331 7.78056 69.4046 7.78056C68.4998 7.78056 67.7379 8.04651 67.1665 8.60059C66.595 9.15467 66.3093 9.86389 66.3093 10.7283V19.2167H64.1665V10.7283C64.1665 9.86389 63.8808 9.15467 63.2855 8.60059C62.7141 8.04651 61.9522 7.78056 61.0474 7.78056C60.1189 7.78056 59.357 8.04651 58.7855 8.60059C58.2141 9.15467 57.9046 9.86389 57.9046 10.7283V19.2167H55.7617V10.7283C55.7617 9.35414 56.2617 8.22382 57.2617 7.33729C58.2617 6.45077 59.5236 6.00751 61.0236 6.00751C62.9522 6.00751 64.3808 6.71673 65.2617 8.13517C66.1665 6.71673 67.5712 6.00751 69.476 6.00751C71.0236 6.00751 72.2617 6.45077 73.2379 7.33729C74.2141 8.22382 74.6903 9.35414 74.6903 10.7283Z"
                    fill="inherit"
                  ></path>
                  <path
                    d="M77.1902 12.8116C77.1902 10.8391 77.8569 9.22116 79.214 7.9357C80.5711 6.65024 82.2854 6.00751 84.4045 6.00751C85.714 6.00751 86.833 6.27346 87.8092 6.80538C88.7616 7.33729 89.4044 7.95786 89.7378 8.71141H89.7854C89.7378 8.20166 89.7378 7.75839 89.7378 7.33729V6.40644H91.7616V19.2167H89.7378V18.0421C89.7378 17.6431 89.7616 17.1777 89.7854 16.6458H89.7378C89.4044 17.5102 88.7378 18.2194 87.7854 18.7735C86.8092 19.3275 85.6902 19.6157 84.3806 19.6157C82.2378 19.6157 80.4997 18.9729 79.1902 17.6875C77.8568 16.4242 77.1902 14.7841 77.1902 12.8116ZM84.4997 17.8204C85.9997 17.8204 87.2378 17.355 88.214 16.402C89.1902 15.449 89.6664 14.2522 89.6664 12.8116C89.6664 11.371 89.1902 10.152 88.214 9.22116C87.2378 8.26815 85.9997 7.80272 84.4997 7.80272C83.0235 7.80272 81.8092 8.26815 80.833 9.22116C79.8568 10.1742 79.3807 11.371 79.3807 12.8116C79.3807 14.2522 79.8568 15.449 80.833 16.402C81.8092 17.355 83.0235 17.8204 84.4997 17.8204Z"
                    fill="inherit"
                  ></path>
                  <path
                    d="M104.476 19.239V0.488953H106.619V11.903C106.881 11.9473 107.357 11.9473 108.024 11.9473C109.5 11.9473 110.667 11.5927 111.524 10.8613C112.381 10.1299 112.809 9.13257 112.809 7.82495C112.809 7.33736 112.762 6.87193 112.69 6.40651H114.857C114.905 6.82761 114.928 7.31519 114.928 7.84711C114.928 8.99959 114.667 10.0191 114.119 10.9499C113.571 11.8586 112.786 12.5679 111.738 13.0554L115.667 19.2168H113.167L109.667 13.6538C109.024 13.7203 108.5 13.7647 108.071 13.7647C107.524 13.7647 107.024 13.7425 106.595 13.6982V19.239H104.476Z"
                    fill="inherit"
                  ></path>
                  <path
                    d="M129.666 17.067C128.285 18.7735 126.404 19.6379 124 19.6379C121.904 19.6379 120.166 18.9951 118.785 17.6875C117.404 16.3799 116.714 14.762 116.714 12.8116C116.714 10.8613 117.404 9.24338 118.785 7.95792C120.166 6.67245 121.881 6.02972 123.976 6.02972C125.952 6.02972 127.595 6.67245 128.857 7.95792C130.119 9.24338 130.761 10.8613 130.761 12.8116C130.761 13.1219 130.738 13.41 130.714 13.676H118.976C119.166 14.9171 119.738 15.9366 120.642 16.6902C121.547 17.4437 122.69 17.8205 124.023 17.8205C125.809 17.8205 127.261 17.1556 128.404 15.848L129.666 17.067ZM123.952 7.78061C122.619 7.78061 121.523 8.15738 120.619 8.93309C119.714 9.7088 119.166 10.7283 119 11.9694H128.642C128.547 10.7283 128.071 9.73097 127.214 8.95526C126.333 8.17955 125.261 7.78061 123.952 7.78061Z"
                    fill="inherit"
                  ></path>
                  <path
                    d="M133.619 17.1334V8.15735H132.024V6.40647H133.619V2.99335H135.762V6.40647H140V8.15735H135.762V16.8453C135.762 17.1777 136.048 17.4659 136.429 17.4659H139.595V19.2389H135.881C134.619 19.2389 133.619 18.2859 133.619 17.1334Z"
                    fill="inherit"
                  ></path>
                  <path
                    d="M97.6665 9.15474V6.56166H95.6189V19.239H97.6665V16.668L97.6427 13.1219C97.6427 10.4845 99.5475 8.55634 102.119 8.35687V6.34003C100.143 6.58382 98.5951 7.62549 97.6665 9.15474Z"
                    fill="inherit"
                  ></path>
                </svg>
              </div>
            </Link>
          </motion.div>
        </div>

        <div className="footer">
          More&nbsp;exchange listings are&nbsp;on&nbsp;the&nbsp;way
        </div>
      </div>
    </>
  );
};

export default Network2;
