const { sendXrplCommand } = require("./sendXrplCommand");

const getAccountLinesCommand = ({
  id = undefined,
  account, // String - Address	Look up trust lines connected to this account.
  marker = undefined, // Marker	(Optional) Value from a previous paginated response. Resume retrieving data where that response left off.
  limit = undefined, // Number	(Optional) Limit the number of trust lines to retrieve. The server may return less than the specified limit, even if there are more pages of results. Must be within the inclusive range 10 to 400. Positive values outside this range are replaced with the closest valid option. The default is 200.
  ledger_hash = undefined, // String	(Optional) A 20-byte hex string for the ledger version to use. (See Specifying Ledgers)
  ledger_index = "validated", // Number or String	(Optional) The ledger index of the ledger to use, or a shortcut string to choose a ledger automatically. (See Specifying Ledgers)
  peer = undefined, // String - Address	(Optional) A second account; if provided, filter results to trust lines connecting the two accounts.
}) => {
  return {
    command: "account_lines",
    id,
    account,
    marker,
    limit,
    ledger_hash,
    ledger_index,
    peer,
  };
};

const parseAccountLines = ({ accountLines, account }) => {
  let holders = [];
  let holderTrustlines = [];
  let holderBalances = [];
  let totalHolderBalancesObj = {};

  let holdings = [];
  let holdingTrustlines = [];
  let holdingBalances = [];
  let totalHoldingBalancesObj = {};

  for (let line of accountLines) {
    const isHolder = Number(line.balance) <= 0;
    // const isHolder = line.balance.charAt(0) === "-";
    const balance = Math.abs(Number(line.balance));

    if (isHolder) {
      const limit = Math.abs(Number(line.limit_peer));
      const currency = line.currency;
      const issuer = account;
      const lineAccount = line.account;
      const tokenObject = {
        currency,
        issuer,
        account: lineAccount,
        limit,
        balance,
      };

      if (totalHolderBalancesObj?.[currency]) {
        totalHolderBalancesObj[currency] += balance;
      } else {
        totalHolderBalancesObj[currency] = balance;
      }

      holders.push(tokenObject);
      if (balance > 0) {
        holderBalances.push(tokenObject);
      } else {
        holderTrustlines.push(tokenObject);
      }
    } else {
      const limit = Math.abs(Number(line.limit));
      const currency = line.currency;
      const issuer = line.account;
      const tokenObject = { currency, issuer, account, limit, balance };
      const tokenString = `${currency}-${issuer}`;

      if (totalHoldingBalancesObj?.[tokenString]) {
        totalHoldingBalancesObj[tokenString] += balance;
      } else {
        totalHoldingBalancesObj[tokenString] = balance;
      }

      holdings.push(tokenObject);
      if (balance > 0) {
        holdingBalances.push(tokenObject);
      } else {
        holdingTrustlines.push(tokenObject);
      }
    }
  }

  const totalHolderBalances = Object.entries(totalHolderBalancesObj).map(
    ([currency, balance]) => {
      return {
        currency,
        issuer: account,
        balance,
      };
    }
  );
  const totalHoldingBalances = Object.entries(totalHoldingBalancesObj).map(
    ([tokenString, balance]) => {
      const [currency, issuer] = tokenString.split("-");
      return {
        currency,
        issuer,
        balance,
      };
    }
  );

  const sortByBalanceAndLimit = (a, b) => {
    if (a.balance === b.balance) return b?.limit - a?.limit;
    return b.balance - a.balance;
  };

  holders.sort(sortByBalanceAndLimit);
  holderTrustlines.sort(sortByBalanceAndLimit);
  holderBalances.sort(sortByBalanceAndLimit);
  totalHolderBalances.sort(sortByBalanceAndLimit);

  holdings.sort(sortByBalanceAndLimit);
  holdingTrustlines.sort(sortByBalanceAndLimit);
  holdingBalances.sort(sortByBalanceAndLimit);
  totalHoldingBalances.sort(sortByBalanceAndLimit);

  return {
    holders,
    holderTrustlines,
    holderBalances,
    totalHolderBalances,
    totalHolderBalancesObj,

    holdings,
    holdingTrustlines,
    holdingBalances,
    totalHoldingBalances,
    totalHoldingBalancesObj,
  };
};

const getAccountLines = async ({
  consoleLog,
  xrplClient,
  id,
  account,
  marker,
  limit,
  ledger_hash,
  ledger_index,
  peer,
}) => {
  const command = getAccountLinesCommand({
    id,
    account,
    marker,
    limit,
    ledger_hash,
    ledger_index,
    peer,
  });
  const response = await sendXrplCommand({ consoleLog, xrplClient, command });
  return response;
};

const getAllAccountLines = async ({
  consoleLog,
  xrplClient,
  account,
  limit,
  ledger_hash,
  ledger_index,
  peer,
  maxErrors = 0,
}) => {
  let lines = [];
  let marker;
  let errorCount = 0;
  while (true) {
    const response = await getAccountLines({
      consoleLog,
      xrplClient,
      account,
      marker: marker ? marker : undefined,
      limit,
      ledger_hash,
      ledger_index,
      peer,
    });

    if (response?.error) {
      errorCount++;
      if (errorCount > maxErrors) return response;
    } else {
      errorCount = 0;

      lines =
        response?.lines?.length > 0 ? [...lines, ...response.lines] : lines;

      marker = response?.marker ? response.marker : undefined;
      if (!marker) return lines;
    }
  }
};

const getAccountLinesParsed = async ({
  consoleLog,
  xrplClient,
  account,
  ledgerIndex,
}) => {
  const accountLines = await getAllAccountLines({
    consoleLog,
    xrplClient,
    account,
    ledger_index: ledgerIndex,
  });
  if (accountLines?.error) return accountLines;

  const {
    holders,
    holderTrustlines,
    holderBalances,
    totalHolderBalances,
    totalHolderBalancesObj,

    holdings,
    holdingTrustlines,
    holdingBalances,
    totalHoldingBalances,
    totalHoldingBalancesObj,
  } = parseAccountLines({ accountLines, account });

  return {
    holders,
    holderTrustlines,
    holderBalances,
    totalHolderBalances,
    totalHolderBalancesObj,

    holdings,
    holdingTrustlines,
    holdingBalances,
    totalHoldingBalances,
    totalHoldingBalancesObj,
  };
};
exports.getAccountLinesParsed = getAccountLinesParsed;
