import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Socials = () => {
  return (
    <>
      <div className="content-box socials other">
        <div className="header">
          <div className="title">Socials</div>

          <div className="text">Join the $BEAR community</div>
        </div>

        <div className="content">
          <div className="socials">
            <Link
              className="social twitter"
              to="https://twitter.com/BearXRPL"
              target="_blank"
              rel="noopener noreferrer"
            >
              <motion.div
                className="icon"
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
                layout="position"
              >
                <img src="images/socials/twitter.svg" alt="" />
              </motion.div>
              {/* <div className="twitter-handle">@bearXRPL</div> */}
            </Link>

            <Link
              className="social discord"
              to="https://discord.gg/atGTNuzqH6"
              target="_blank"
              rel="noopener noreferrer"
            >
              <motion.div
                className="icon"
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
                layout="position"
              >
                <img src="images/socials/discord.jpeg" alt="" />
              </motion.div>
            </Link>
          </div>

          <div className="footer">
            <div className="button-box">
              <div className="content">
                <motion.button
                  initial={{ rotate: "-5deg" }}
                  whileHover={{ scale: 1.05 }}
                  transition={{ duration: 0.3 }}
                  className="button"
                  layout="position"
                >
                  <Link
                    className="link"
                    to="https://xmagnetic.org/dex/BEAR+rBEARGUAsyu7tUw53rufQzFdWmJHpJEqFW_XRP+XRP?network=mainnet"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Click to buy $BEAR
                  </Link>
                </motion.button>

                <div className="logo">BEAR</div>
              </div>
            </div>
            <div className="image-box">
              <div className="image">
                <img src="images/sunflower.gif" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Socials;
